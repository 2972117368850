<template>
  <div class="searcha-area">
    <div class="s-type-list">
      <label @click="groupa">
        <span :class="{ active: tablesle === '搜索' }">搜索</span>
      </label>
      <label @click="groupb">
        <span :class="{ active: tablesle === '生活' }">生活</span>
      </label>
      <label @click="groupc">
        <span :class="{ active: tablesle === '音影' }">音影</span>
      </label>
    </div>

    <form
      ref="searchForm"
      @submit.prevent="handleSubmit"
      class="super-search-fm"
      @click="clickInput"
    >
      <input
        id="myDiv"       
        v-model="searchKeyword"
        class="search-input"
        type="text"
        ref="myInput"
        :placeholder="placeholder"
        @blur="blurInput"
        @input="inputChanged"
      />

      <span
        class="iconfont icon-fanyi imgbg"
        :class="transimg ? 'img-red' : 'img-black'"
        @click="translate"
      ></span>
      <button hidden="true" type="submit"></button>
    </form>

    <div class="type-list">
      <div class="search-group group-a" v-if="groupashow">
        <ul class="search-type">
          <li>
            <div @click="baiduSearch">
              <input
                checked="checked"
                hidden="true"
                type="radio"
                name="type"
                id="type-baidu"
                value="https://www.baidu.com/s?wd="
                placeholder="百度一下"
              />
            </div>
            <label for="type-baidu">
              <span :class="{ typeactive: searchtype === '百度' }">百度</span>
            </label>
          </li>
          <li>
            <div @click="googleSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-google"
                value="https://www.google.com/search?q="
                placeholder="谷歌两下"
              />
            </div>
            <label for="type-google">
              <span :class="{ typeactive: searchtype === '谷歌' }">谷歌</span>
            </label>
          </li>
          <li>
            <div @click="bingSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-bing"
                value="https://www.bing.com/search?q="
                placeholder="必应搜索"
              />
            </div>
            <label for="type-bing">
              <span :class="{ typeactive: searchtype === '必应' }">必应</span>
            </label>
          </li>
          <li>
            <div @click="zhihuSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-zhihu"
                value="https://www.zhihu.com/search?q="
                placeholder="知乎"
              />
            </div>
            <label for="type-zhihu">
              <span :class="{ typeactive: searchtype === '知乎' }">知乎</span>
            </label>
          </li>
          <li>
            <div @click="weixinSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-weixin"
                value="https://weixin.sogou.com/weixin?type=2&query="
                placeholder="微信"
              />
            </div>
            <label for="type-weixin">
              <span :class="{ typeactive: searchtype === '微信' }">微信</span>
            </label>
          </li>
          <!-- 添加更多搜索引擎 -->
        </ul>
      </div>

      <div class="search-group group-b" v-if="groupbshow">
        <ul class="search-type">
          <li>
            <div @click="taobaoSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-taobao"
                value="https://s.taobao.com/search?q="
                placeholder="淘宝"
              />
            </div>
            <label for="type-taobao">
              <span :class="{ typeactive: searchtype === '淘宝' }">淘宝</span>
            </label>
          </li>
          <li>
            <div @click="jdSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-jd"
                value="https://search.jd.com/Search?keyword="
                placeholder="京东"
              />
            </div>
            <label for="type-jd">
              <span :class="{ typeactive: searchtype === '京东' }">京东</span>
            </label>
          </li>
          <li>
            <div @click="fySearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-fanyi"
                value="https://fanyi.baidu.com/translate?aldtype=16047&query=#zh/en/"
                placeholder="翻译"
              />
            </div>
            <label for="type-fanyi">
              <span :class="{ typeactive: searchtype === '翻译' }">翻译</span>
            </label>
          </li>
          <li>
            <div @click="doubanSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-douban"
                value="https://www.douban.com/search?q="
                placeholder="豆瓣"
              />
            </div>
            <label for="type-douban">
              <span :class="{ typeactive: searchtype === '豆瓣' }">豆瓣</span>
            </label>
          </li>
          <li>
            <div @click="imageSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-image"
                value="https://image.baidu.com/search/index?tn=baiduimage&ps=1&ct=201326592&lm=-1&cl=2&nc=1&ie=utf-8&dyTabStr=MCwzLDYsMSw0LDIsNSw3LDgsOQ%253D%253D&word="
                placeholder="图片"
              />
            </div>
            <label for="type-image">
              <span :class="{ typeactive: searchtype === '图片' }">图片</span>
            </label>
          </li>
          <!-- 添加更多搜索引擎 -->
        </ul>
      </div>
      <div class="search-group group-c" v-if="groupcshow">
        <ul class="search-type">
          <li>
            <div @click="bilibiliSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-bilibili"
                value="https://search.bilibili.com/all?keyword="
                placeholder="B站"
              />
            </div>
            <label for="type-bilibili">
              <span :class="{ typeactive: searchtype === 'B站' }">B站</span>
            </label>
          </li>
          <li>
            <div @click="musicSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-music"
                value="searchmusic"
                placeholder="音乐"
              />
            </div>
            <label for="type-music">
              <span :class="{ typeactive: searchtype === '音乐' }">音乐</span>
            </label>
          </li>
          <li>
            <div @click="douyinSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-douyin"
                value="https://www.douyin.com/search/"
                placeholder="抖音"
              />
            </div>
            <label for="type-douyin">
              <span :class="{ typeactive: searchtype === '抖音' }">抖音</span>
            </label>
          </li>
          <li>
            <div @click="youkuSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-youku"
                value="https://so.youku.com/search_video/q_"
                placeholder="优酷"
              />
            </div>
            <label for="type-youku">
              <span :class="{ typeactive: searchtype === '优酷' }">优酷</span>
            </label>
          </li>
          <li>
            <div @click="yingshiSearch">
              <input
                hidden="true"
                type="radio"
                name="type"
                id="type-yingshi"
                value="https://search.douban.com/movie/subject_search?search_text="
                placeholder="影视"
              />
            </div>
            <label for="type-yingshi">
              <span :class="{ typeactive: searchtype === '影视' }">影视</span>
            </label>
          </li>
          <!-- 添加更多搜索引擎 -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "SearchBox",
  data() {
    return {
      imageIndex: 0,
      imageList: ["/assets/images/fy-blue.png", "/assets/images/fy-red.png"],
      groupashow: true,
      groupbshow: false,
      groupcshow: false,
      placeholder: "请输入关键字搜索",
      tablesle: "搜索",
      searchtype: "",
      transimg: false,
      songinfo: false,
      searchKeyword: "",
      selectedSearchType: "https://www.baidu.com/s?wd="
    };
  },
  computed: {
    currentImage() {
      return this.imageList[this.imageIndex];
    }
  },

  mounted() {
    this.$eventBus.$on("songname", this.getsongname);
    this.$eventBus.$on("audioplay", this.audioplay);
    this.$eventBus.$on("noaudioplay", this.noaudio);
  },
  beforeDestroy() {
    this.$eventBus.$off("songname", this.getsongname);
    this.$eventBus.$off("audioplay", this.audioplay);
    this.$eventBus.$off("noaudioplay", this.noaudio);
  },
  methods: {
    getsongname(songname) {
      if (this.songinfo) {
        this.placeholder = songname;
      }
    },

    audioplay(audioplay) {
      if (audioplay == "audioplay") {
        this.songinfo = true;
      }
    },

    noaudio(audioplay) {
      if (audioplay == "noaudioplay") {
        this.placeholder = "请输入关键字搜索";
      }
    },

    clickInput() {
      this.$eventBus.$emit("hide-side-bar");
    },
    inputChanged: function() {
      this.$eventBus.$emit("hide-side-bar");
    },
    blurInput() {
      this.$eventBus.$emit("show-side-bar");
    },
    focusInput() {
      this.$refs.myInput.focus();
    },

    async translate() {
      if (!this.searchKeyword) {
        Toast.setDefaultOptions({ duration: 500 });
        Toast("请输入后在进行翻译！");
        return;
      }
      this.transimg = !this.transimg;

      const data = this.searchKeyword;
      const result = await this.gettranslate(data);
      this.searchKeyword = result.data.translation;
    },
    groupa() {
      this.groupashow = true;
      this.groupbshow = false;
      this.groupcshow = false;
      this.tablesle = "搜索";
      this.placeholder = "百度一下";
      this.selectedSearchType = "https://www.baidu.com/s?wd=";
      this.searchtype = "百度";
      this.focusInput();
    },
    groupb() {
      this.groupashow = false;
      this.groupbshow = true;
      this.groupcshow = false;
      this.tablesle = "生活";
      this.placeholder = "淘宝";
      this.selectedSearchType = "https://s.taobao.com/search?q=";
      this.searchtype = "淘宝";
      this.focusInput();
    },
    groupc() {
      this.groupashow = false;
      this.groupbshow = false;
      this.groupcshow = true;
      this.tablesle = "音影";
      this.placeholder = "B站";
      this.selectedSearchType = "https://search.bilibili.com/all?keyword=";
      this.searchtype = "B站";
      this.focusInput();
    },
    baiduSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "百度";
      this.focusInput();
    },
    googleSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "谷歌";
      this.focusInput();
    },
    zhihuSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "知乎";
      this.focusInput();
    },
    weixinSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "微信";
      this.focusInput();
    },

    bingSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "必应";
      this.focusInput();
    },
    taobaoSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "淘宝";
      this.focusInput();
    },
    jdSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "京东";
      this.focusInput();
    },
    fySearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "翻译";
      this.focusInput();
    },
    doubanSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "豆瓣";
      this.focusInput();
    },
    imageSearch(e) {
      console.log(e.target.value);
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "图片";
      this.focusInput();
    },
    bilibiliSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "B站";
      this.focusInput();
    },
    musicSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "音乐";
      this.focusInput();
    },
    douyinSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "抖音";
      this.focusInput();
    },
    youkuSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "优酷";
      this.focusInput();
    },
    yingshiSearch(e) {
      this.selectedSearchType = e.target.value;
      this.placeholder = e.target.placeholder;
      this.searchtype = "影视";
    },
    handleSubmit() {
      if (this.selectedSearchType === "searchmusic") {
        this.$eventBus.$emit("search", this.searchKeyword);
        this.$refs.myInput.blur();
        this.searchKeyword = null;
        return;
      }
      const searchLink = this.selectedSearchType + this.searchKeyword;
      window.open(searchLink, "_blank");
    }
  }
};
</script>


<style scoped>
.active {
  border-bottom: 4px solid red;
}

.imgbg {
  position: absolute;
  right: 15px;
  transform: scale(1.6);
}
.ing-black {
  color: #000;
}
.img-red {
  color: #ff0000;
}

.typeactive {
  color: #10e010;
  font-size: 20px;
}
.red-bg {
  color: red;
}

.blue-bg {
  color: blue;
}
.searcha-area {
  position: relative;
  width: 80%;
  margin: 0 auto;
  border: 0px solid #8b1010;
}

.s-search-input {
  width: 100%;
  height: 32px;
  border: none;
}
.search-fm::placeholder {
  color: #7c0516;
  font-size: 15px;
  opacity: 0.6;
}

.s-type-list {
  position: relative;
  width: 30%;
  margin-left: 35%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 6px;
}

.s-type-list label {
  margin-right: 10px;
  color: rgb(249, 246, 246);
  cursor: pointer;
  font-size: 25px;
  font-weight: 300;
}

.super-search-fm {
  position: relative;
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.super-search-fm input[type="text"] {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding: 11px;
  border: #ede6e6 0px solid;
  border-radius: 15px;
  outline: 0;
}

.super-search-fm button {
  width: 50px;
  height: 36px;
  border: #8b1010 0px solid;
  border-radius: 4px;
  background-color: #27108b;
  color: #fff;
  cursor: pointer;
  outline: 0;
}

.search-group ul {
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  border: #8b1010 0px solid;

  padding: 0;
}

.search-group label {
  cursor: pointer;
}

.search-group label {
  font-size: 20px;
  color: #f8f6f6;
}

@media (max-width: 979px) {
  .searcha-area {
    width: 100%;
  }
  .search-input::placeholder {
    font-size: 15px;
  }
  .s-type-list {
    width: 50%;
    font-size: 22px;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .super-search-fm {
    width: 80%;
  }

  .super-search-fm input[type="text"] {
    padding: 8px;
  }

  .search-group ul {
    width: 80%;
  }
}
</style>