<template>
  <div id="app" :style="{ height: windowHeight + 'px' }" class="contentarea">
    <div class="content-wrapper" ref="wrapper">  
      <Header></Header>      
      <ContentPage class="content-page"></ContentPage>
    </div>
    <BootMusic v-if="musicshow" class="boot-music"></BootMusic>
    <SideBar></SideBar>
      <FootBar class="foot-bar"></FootBar>
   
  </div>
</template>
<!-- eslint-disable -->
<script>

import Header from './components/Header.vue';
import ContentPage from './components/ContentPage.vue';
import FootBar from './components/FootBar.vue';
import SideBar from './components/SideBar.vue';
import BootMusic from './components/BootMusic.vue';

export default {
  name: 'App',
  components: {
    Header,
    ContentPage,
    FootBar,
    SideBar,
    BootMusic
  },
  data() {
    return {
      show: true,
      windowHeight: 0 ,
      musicshow: true    
    }
  },
  created() {
    this.$eventBus.$on('scroll-to-top', this.scrollTop);  
    this.$eventBus.$on('openmusic', this.openmusic);  
    
    this.$eventBus.$on('closemusic', this.closemusic);  
  },
  mounted() {
  this.windowHeight = window.innerHeight;
  
},
  beforeDestroy() {
    this.$eventBus.$off('scroll-to-top', this.scrollTop);
  },
  
  methods: {
    openmusic() {
      this.musicshow = true;
    },
    closemusic() {
      this.musicshow = false;
    },
    scrollTop() {
      this.$refs.wrapper.scrollTo({ top: 0, behavior: 'smooth' });
    },
     },  
}
</script>
<style >
body,
html {
  position: fixed;
  width: 100%;
  margin: 0;
  padding: 0; 
  scrollbar-width: none;
}

* {
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.contentarea {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgb(241, 240, 240);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
}

.content-wrapper {
  height: calc(100% - var(--footer-height));
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-right: 12px;

  background: linear-gradient(to right, rgb(241, 240, 240) 0%, rgb(221, 220, 220) 100%);
  -webkit-overflow-scrolling: touch;
 
}
.content-page{
  flex: 1; 
  padding-bottom: calc(var(--boot-music-height))!important;
}

.content-wrapper img {
  pointer-events: none;
  -webkit-user-drag: none;
}

.boot-music{
  position: fixed;
  left: 0;
  bottom: calc(var(--footer-height));
  z-index: 98;
}

.foot-bar {
  height: var(--footer-height);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  :root {
    --footer-height: 7vh;
  }
  .content-wrapper {
    height: calc(100% - var(--footer-height));
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  :root {
    --footer-height: 5vh;
  }
  .content-wrapper {
    height: calc(100% - var(--footer-height));
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --footer-height: 9vh;
  }
  .content-wrapper {
    height: calc(100% - var(--footer-height));
  }
}

:root {
  --footer-height: 9vh;  /* 默认值 */
  --boot-music-height: 10vh;  /* BootMusic 组件的高度，根据实际情况调整 */
}

/* 大屏设备 (>=1200px) */
@media screen and (min-width: 1200px) {
  :root {
    --footer-height: 9vh;
  }
}

/* 平板设备 (768px - 1199px) */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  :root {
    --footer-height: 8vh;
  }
}

/* 手机设备 (<768px) */
@media screen and (max-width: 767px) {
  :root {
    --footer-height: 7vh;
  }
}

/* 横屏模式特殊处理 */
@media (orientation: landscape) and (max-height: 600px) {
  :root {
    --footer-height: 12vh;
  }
}

.content-wrapper {
  height: calc(100% - var(--footer-height));
 
}

.boot-music {
  bottom: calc(var(--footer-height) + env(safe-area-inset-bottom, 0px));
  
}        

.foot-bar {
  height: var(--footer-height);
  
}

/* Safari 特定的媒体查询 */
@supports (-webkit-touch-callout: none) {
  .contentarea {
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }
  
  .content-wrapper {
    height: calc(100vh - var(--footer-height) - env(safe-area-inset-top));
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    position: relative;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    overscroll-behavior: none;
  }
  
  .foot-bar {
    padding-bottom: env(safe-area-inset-bottom, 0px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  /* iPhone底部手势区域处理 */
  @supports (padding: max(0px)) {
    .foot-bar {
      padding-bottom: max(env(safe-area-inset-bottom, 0px), 20px);
    }
  }

  /* 针对横屏模式的优化 */
  @media (orientation: landscape) {
    .content-wrapper {
      height: calc(100vh - var(--footer-height) - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
  }

  .boot-music {
    bottom: calc(var(--footer-height) + env(safe-area-inset-bottom, 0px));
    position: fixed;
    left: 0;
    z-index: 98;
  }

  .content-page {
    padding-bottom: calc( var(--boot-music-height) + env(safe-area-inset-bottom, 0px))!important;
  }
}
</style>

