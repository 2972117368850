<template>
  <div class="flex-column" v-if="show">
    <span class="iconfont icon-wenjianchuanshu imgbg" @click="note"></span>

    <span class="iconfont icon-zhidingtop imgbg" @click="gotop"></span>

    <span class="iconfont icon-xiayiqu1 imgbg" @click="nextsong"></span>

    <span class="iconfont icon-gedan imgbg" @click="list"></span>

    <el-dialog :visible.sync="noteshow" :fullscreen="fullscreen" :modal-append-to-body="false">
      <NotePage></NotePage>
    </el-dialog>
    <el-dialog
      :visible.sync="listshow"
      width="310px"
      title="歌单"
      center
      :modal-append-to-body="false"
    >
      <input
        type="text"
        style="
          width: 98%;
          text-align: center;
          margin-bottom: 15px;
          height: 30px;
          border-radius: 5px;
          border: 1px solid #ccc;
        "
        placeholder="搜索歌曲....."
        v-model="keywords"
        @keyup.enter="handleSearch"
        @keydown.enter="handleSearch"
      />
      <div style="max-height: 250px; overflow: auto">
        <span
          v-for="(playlist, index) in playlist"
          :key="playlist.playlistid"
          class="songlist"
          @click="listplay(playlist.playlistid)"
        >{{ index + 1 }}: {{ playlist.playlistname }}</span>
      </div>
    </el-dialog>
  </div>
</template>
<!-- eslint-disable -->
<script>
import NotePage from "./Notelist.vue";

export default {
  name: "SideBar",
  components: {
    NotePage
  },

  data() {
    return {
      fullscreen: true,
      playlist: [],
      keywords: "",
      noteshow: false,
      listshow: false,
      show: true
    };
  },
  created() {
    this.$eventBus.$on("hide-side-bar", this.sidehide);
    this.$eventBus.$on("show-side-bar", this.sideshow);
    this.$eventBus.$on("close-note", this.closenote);
  },
  beforeDestroy() {
    this.$eventBus.$off("hide-side-bar", this.sidehide);
    this.$eventBus.$off("show-side-bar", this.sideshow);
    this.$eventBus.$off("close-note", this.closenote);
  },
  methods: {
    listplay(playlistid) {
      this.$eventBus.$emit("playlist", playlistid);
      this.listshow = false;
    },
    search() {
      this.$eventBus.$emit("search", this.keywords);
      this.keywords = null;
    },
    handleSearch() {
      this.search();
      this.listshow = false;
    },
    list() {
      this.listshow = true;
      this.getlist();
    },

    async getlist() {
      const result = await this.getplaylist();
      this.playlist = result.data;
    },

    sideshow() {
      this.show = true;
    },
    sidehide() {
      this.show = false;
    },
    gotop() {
      this.$eventBus.$emit("scroll-to-top");
    },
    nextsong() {
      this.$eventBus.$emit("nextsong");
    },

    note() {
      this.noteshow = true;
      this.$eventBus.$emit("notenew");
      this.$eventBus.$emit("closemusic");
    },
    closenote() {
      this.noteshow = false;
      this.$eventBus.$emit("openmusic");      
    }
  }
};
</script>

<style scoped>
.flex-column {
  position: fixed;
  bottom: 80px;
  right: 2px;
  width: 30px;
  height: 250px;
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  border: #8b1010 0px solid;
}

.imgbg {
  border-radius: 50%;
  transform: scale(1);
  color: #fff;
  background-color: #737171;
  opacity: 0.6;
  padding: 7px;
  transition: all 0.3s ease;
}

.imgbg:hover {
  transform: scale(1.2);
  color: #000;
  opacity: 1;
}

.songlist {
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .flex-column {
    position: fixed;
    bottom: 140px;
    right: 2px;
    width: 30px;
    height: 250px;
    display: flex;

    flex-direction: column;
    justify-content: space-around;
    border: #8b1010 0px solid;
  }

  .imgbg {
    border-radius: 50%;
    transform: scale(0.8);
    color: #fff;
    background-color: #737171;
    opacity: 0.6;
    padding: 7px;
    transition: all 0.3s ease;
  }

  .imgbg:hover {
    border-radius: 50%;
    transform: scale(0.8);
    color: #fff;
    background-color: #737171;
    opacity: 0.6;
    padding: 7px;
    transition: all 0.3s ease;
  }
}
@media (min-width: 48rem) and (max-width: 64rem) {
  .flex-column {
    position: fixed;
    bottom: 150px;
    right: 2px;
    width: 30px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: #8b1010 0px solid;
  }
}
</style>
