<template>
  <div class="container">
    <div class="close-btn">
      <el-button type="text" icon="el-icon-close" @click="closeDialog" class="close-icon"></el-button>
    </div>



    <div class="editorcontainer">

      <div style="width: 100%; display: flex; align-items: center;margin-bottom: 5px;">
        <div class="til">文字粘贴</div>
        <el-button type="success" class="loadfile" style="margin-right: 40px;" @click="saveContent"
          size="mini">点击保存</el-button>
      </div>
      <vue-editor v-model="content" class="editor" :editor-toolbar="customToolbar"></vue-editor>
    </div>



    <div class="table-container">
      <div>
        <div style="width: 100%; display: flex; align-items: center;margin-bottom: 5px;">
          <div class="til">文件传输</div>
          <el-button type="success" class="loadfile" style="margin-right: 35px;" @click="chooseFile"
            size="mini">选择文件</el-button>
        </div>

        <el-progress v-if="progress > 0" :percentage="progress"></el-progress>
        <input type="file" ref="fileInput" @change="uploadFile" style="display: none;" />
        <el-table size="small" :data="fileList" highlight-current-row border height="280" style="width: 100%;"
          :header-cell-style="{ padding: '8px 0' }" :cell-style="{ padding: '8px 0' }">
          <el-table-column align="center" label="序号" width="50">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column prop="name" label="文件名" min-width="120">
          </el-table-column>

          <el-table-column align="center" prop="size" label="大小" width="60">
            <template slot-scope="scope">{{ scope.row.size }}M</template>
          </el-table-column>

          <el-table-column align="center" label="操作" :width="operationColumnWidth">
            <template slot-scope="scope">
              <div class="operation-buttons">
                <el-button type="primary" size="mini" @click="down(scope.row.url)">
                  <i class="el-icon-download"></i>
                  <span class="button-text">下载</span>
                </el-button>
                <el-button type="danger" size="mini" @click="deleteFile(scope.row)">
                  <i class="el-icon-delete"></i>
                  <span class="button-text">删除</span>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>




  </div>
</template>
<!-- eslint-disable -->
<script>
import { VueEditor } from "vue2-editor";
import { Toast } from "vant";
export default {
  name: "Notelist",
  components: {
    VueEditor
  },
  data() {
    return {
      fileList: [],
      progress: 0,
      content: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ["code-block"],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ["clean"]
      ],
      isMobile: false,
    };
  },
  created() {
    this.$eventBus.$on("notenew", this.notenew);
  },
  beforeDestroy() {
    this.$eventBus.$off("notenew", this.notenew);
    window.removeEventListener('resize', this.checkScreenSize);
  },

  mounted() {
    this.getFileList();
    this.getload();
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    notenew() {
      this.getFileList();
      this.getload();
    },
    down(url) {
      window.open(url, "_blank");
    },

    async getload() {
      const response = await this.loadtext();
      this.content = response.data.content;
    },

    async saveContent() {
      const content = this.content;
      const response = await this.savetext(content);
      Toast(response.data.message);
    },

    chooseFile() {
      this.$refs.fileInput.click();
    },
    async uploadFile(event) {
      try {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);

        const response = await this.savefile(formData, progressEvent => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        });

        const result = response.data.message;
        if (result === "文件上传成功") {
          this.progress = 0;
          Toast(result);
          this.getFileList();
        } else {
          Toast(result);
          this.progress = 0;
        }
      } catch (error) {
        console.error(error);
        this.progress = 0;
      }
    },
    async deleteFile(row) {
      try {
        const response = await this.deletefile(row.name);
        const result = response.data.message;
        if (result === "文件删除成功") {
          this.getFileList();
          Toast(result);
        } else {
          Toast(result);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getFileList() {
      const response = await this.getfile();

      var result = response.data;
      if (result == "没有找到文件记录") {
        this.fileList = "";
      } else {
        this.fileList = [...response.data].reverse();
      }
    },
    closeDialog() {
      this.$eventBus.$emit('close-note');  // 触发关闭对话框的事件
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 780;
    },
  },
  computed: {
    operationColumnWidth() {
      return this.isMobile ? 90 : 180;
    }
  }
};
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -var(--footer-height);
  width: 100%;
  height: calc(100vh - var(--footer-height)) !important;
  margin: 0;
  padding: 10px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

}

.header-section {
  flex-shrink: 0;
  padding-right: 40px;
}

.editorcontainer {
  flex: 1;
  min-height: 200px;
  margin: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:deep(.ql-toolbar) {
  flex-shrink: 0;
  border-bottom: 1px solid #e4e7ed;
  background: #fff;
  z-index: 1;
}

:deep(.ql-container) {
  flex: 1;
  overflow-y: auto;
}

:deep(.ql-editor) {
  background-image: linear-gradient(#e4e7ed 1px, transparent 1px);
  background-size: 100% 2em;
  background-attachment: local;
  line-height: 2em;
  padding: 0.5em 1em;
  min-height: 100%;
}

:deep(.ql-editor p) {
  margin: 0;
  min-height: 2em;
}

.table-container {
  display: flex;
  flex-direction: column;

}

.til {
  font-size: 20px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 16px;
  position: relative;
  padding-left: 12px;
}

.til::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 18px;
  background: #20db7b;
  border-radius: 2px;
}

.loadfile {
  margin-left: auto !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  transition: all 0.3s ease;
}

.el-button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.el-button--primary {
  background: #20db7b;
  border-color: #20db7b;
}

.el-button--primary:hover {
  background: #1cbd6a;
  border-color: #1cbd6a;
}

.el-button--danger:hover {
  opacity: 0.9;
}

.el-progress {
  margin: 16px 0;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 5px;
  z-index: 10;
  width: 40px;
  display: flex;
  justify-content: center;
}

.close-icon {
  font-size: 24px !important;
  color: #909399 !important;
  padding: 10px !important;
  transition: all 0.3s;
}

.close-icon:hover {
  color: #303133 !important;
  transform: rotate(90deg);
}

@media (max-width: 780px) {
  .container {
    bottom: calc(-1 * var(--footer-height)- 40px) !important;
    height: calc(100vh - var(--footer-height) - 40px) !important;
  }

  .table-container {
    margin-bottom: 10px;
  }

  .editorcontainer {
    flex: 1;
    min-height: 200px;
    margin: 8px 0;
  }

  .editor {
    height: 100%;
    min-height: 200px;
  }

  div[style*="height: 200px; overflow: auto;"] {
    flex: 1;
    min-height: 200px;
    margin-top: 12px;
    overflow: auto;
  }

  .el-table {
    margin-top: 8px;
    font-size: 13px;
  }

  .til {
    font-size: 14px;
    margin-bottom: 0;
  }

  .el-button {
    padding: 3px 6px;
    font-size: 11px;
  }

  .el-progress {
    margin: 6px 0;
  }

  .close-btn {
    top: 8px;
    right: 8px;
    width: 32px;
  }

  .close-icon {
    font-size: 20px !important;
    padding: 6px !important;
  }

  .operation-buttons {
    display: flex;
    gap: 4px;
    justify-content: center;
  }

  .operation-buttons .el-button--mini {
    padding: 4px !important;
    height: 24px !important;
    width: 24px !important;
    border-radius: 4px !important;
  }

  .operation-buttons .el-button--mini i {
    font-size: 14px;
    margin: 0;
  }

  /* 下载按钮样式 */
  .operation-buttons .el-button--primary {
    background-color: #409EFF;
    border-color: #409EFF;
  }

  .operation-buttons .el-button--primary:hover,
  .operation-buttons .el-button--primary:focus {
    background-color: #66b1ff;
    border-color: #66b1ff;
  }

  /* 删除按钮样式 */
  .operation-buttons .el-button--danger {
    background-color: #F56C6C;
    border-color: #F56C6C;
  }

  .operation-buttons .el-button--danger:hover,
  .operation-buttons .el-button--danger:focus {
    background-color: #f78989;
    border-color: #f78989;
  }

  /* 按钮激活状态 */
  .operation-buttons .el-button--mini:active {
    transform: scale(0.95);
  }

  .el-table {
    font-size: 12px !important;
  }

  .el-table td,
  .el-table th {
    padding: 6px 0 !important;
  }

  .el-button.el-button--mini {
    padding: 3px 6px;
    font-size: 10px;
  }

  .el-table td[property="name"] {
    padding-left: 4px !important;
  }
}

input[type="file"] {
  display: none;
}

.operation-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.operation-buttons .el-button--mini {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.operation-buttons .el-button--mini i {
  font-size: 14px;
}

.el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-table td[property="name"] {
  text-align: left;
  padding-left: 16px;
}

.el-table {
  margin-top: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-size: 14px;
  border: 1px solid #e4e7ed;
}
</style>
