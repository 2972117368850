<template>
  <div>
    <div class="song-list" v-if="showlist" :style="{ width: playerWidth + 'px' }">
      <div class="song-item" v-for="(song, index) in list" :key="song.id" @click="playSong(song)"
        :class="{ 'current-song': songid == song.id }">
        <span class="song-index">{{ index + 1 }}:</span>
        <div class="song-details" :class="{ 'current-song': songid == song.id }">
          <span class="song-name">{{ song.name }}</span>
          <span class="song-artist">{{ song.songer }}</span>
        </div>
      </div>
    </div>

    <video v-show="mvshow" class="mv-container" :style="{ width: playerWidth + 'px' }" ref="myvideo" :src="mvurl"
      autoplay controls @play="onVideoPlay"></video>

    <div class="lyrics-container" v-if="lyricshow" ref="lyricsContainer" @click="copylrc"
      :style="{ width: playerWidth + 'px', fontSize: fontSize + 'px' }">
      <div class="lyric-area" @click="toggleFullScreen">
        <i class="el-icon-full-screen fll-screen"></i>
      </div>
      <div class="lyric-line" v-for="(line, index) in lyrics" :key="index"
        :class="{ 'active-line': index === currentLine }">{{ line.lyric }}</div>
    </div>

    <div class="player" ref="player" :class="playshow ? 'panelshow' : ''">
      <div class="avatar-area">
        <img :src="pic" class="player-avatar" @click="togglePlay" />

        <span class="iconfont" :class="isplay ? 'icon-bg-play pic-btn-play ' : 'icon-bg-suspend pic-btn'
          " @click="togglePlay"></span>
      </div>

      <div class="panel" v-show="playshow">
        <div style="display: flex; width: 100%; height: 80%">
          <div class="player-info" @click="copesong">
            <span class="player-song-name">{{ songname }}</span>
            <div class="player-singer-name">—{{ singer }}</div>
          </div>

          <div class="player-btn">
            <span class="iconfont icon-shangyiqu control-btn" @click="prevSong"></span>

            <span class="iconfont control-btn" :class="isplay ? 'icon-bg-play' : 'icon-bg-suspend'"
              @click="togglePlay"></span>

            <span class="iconfont icon-xiayiqu control-btn" @click="nextSong"></span>

            <span class="iconfont icon-diaozhenggeci control-btnci" @click="copelrc"></span>
          </div>
        </div>
        <div class="footer">
          <input type="range" v-model.number="currentTime" :max="duration" @input="seek" />

          <div class="current-time">
            <span>{{ formatTime(currentTime) }}</span>
            <span>/</span>
            <span>{{ formatTime(duration) }}</span>
          </div>
          <span class="iconfont control-btn-volume" :class="isMuted ? 'icon-yangshengqiguan' : 'icon-yangshengqikai'"
            @click="toggleMute"></span>

          <span class="iconfont control-btn-mode" :class="getModeIconClass()" @click="changeMode"></span>

          <span class="iconfont icon-liebiao control-btn-list" @click="togglelist"></span>

          <span class="iconfont icon-qupu control-btn-qupu" :class="issheet ? 'green' : 'nogreen'"
            @click="opsheet"></span>

          <span class="iconfont icon-shipin control-btn-mv" :class="mvid == 0 ? 'nogreen' : 'red'" @click="opmv"></span>
        </div>
      </div>
      <div class="back" @click="toggleback">
        <span class="iconfont back-btn" :class="isback ? 'icon-youjiantou' : 'icon-arrow-left'"></span>
      </div>
    </div>
    <el-dialog :visible.sync="sheetshow" :fullscreen="fullscreen" title="曲谱" center :modal-append-to-body="false"
      @close="sheetclose">
      <SheetPage :sheetlist="songsheet"></SheetPage>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";
import SheetPage from "./SheetList.vue";
export default {
  name: "BootMusic",
  components: {
    SheetPage
  },
  data() {
    return {
      lyc: "",
      mvid: "",
      oldmvurl: '',
      mvshow: false,
      mvurl: "",
      fontSize: 16,
      lyricstext: "",
      fullscreen: true,
      sheetshow: false,
      audio: null,
      progress: 0,
      issheet: false,
      keywords: "",
      currentTime: 0,
      duration: 0,
      audioUrl: "",
      pic: "../assets/pic.png",
      currentLyrics: "",
      lastPlayedTime: "",
      songname: "",
      isMuted: false,
      lyricshow: false,
      showlist: false,
      playliston: false,
      singer: "",
      changesong: false,
      playerWidth: "",
      currentLine: 0,
      isback: true,
      showLyrics: true,
      playshow: false,
      isplay: false,
      lyrics: [],
      songsheet: [],
      list: [],
      mode: "listLoop",
      songid: "",
      api: "https://web.yjl67525.top"
    };
  },
  created() {
    this.$eventBus.$on("nextsong", this.nextSong);
    this.$eventBus.$on("search", this.handlesearch);
    this.$eventBus.$on("playlist", this.handlePlaylist);
  },
  computed: {
    filteredLyrics() {
      return this.lyrics.filter((line, index) => index === this.currentLine);
    }
  },
  watch: {
    mvshow(newValue) {
      this.checkConditions();
    },
    showlist(newValue) {
      this.checkConditions();
    },
    lyricshow(newValue) {
      this.checkConditions();
    },
    currentTime(newTime) {
      const line = this.lyrics.findIndex((item, index, arr) => {
        return (
          item.time <= newTime &&
          arr[index + 1] &&
          arr[index + 1].time > newTime
        );
      });

      if (line !== -1 && line !== this.currentLine) {
        this.currentLine = line;
        this.$nextTick(() => {
          this.scrollToCurrentLine();
        });
      }
    },
    filteredLyrics: {
      handler(newVal) {
        this.currentLyrics = newVal[0] ? newVal[0].lyric : "";
        this.$eventBus.$emit("currentLyrics", this.currentLyrics);
      },
      immediate: true
    },
    lyrics(newLyrics) {
      if (newLyrics.length < 5) {
        this.lyricshow = false;
      }
    }
  },

  mounted() {
    const eventListeners = {
      'resize': this.handleResize,
      'keydown': this.handleKeyDown,
      'fullscreenchange': this.handleFullscreenChange
    };

    Object.entries(eventListeners).forEach(([event, handler]) => {
      window.addEventListener(event, handler);
    });
    
    this.updatePlayerWidth();
    this.getlist();
    this.audio = new Audio();
  },
  beforeDestroy() {
    const eventListeners = {
      'resize': this.handleResize,
      'keydown': this.handleKeyDown,
      'fullscreenchange': this.handleFullscreenChange
    };

    Object.entries(eventListeners).forEach(([event, handler]) => {
      window.removeEventListener(event, handler);
    });

    ['nextsong', 'playlist', 'search'].forEach(event => {
      this.$eventBus.$off(event);
    });
  },
  methods: {
    onVideoPlay() {
      this.oldmvurl = this.mvurl
    },
    updatePlayerWidth() {
      if (this.$refs.player) {
        this.playerWidth = this.$refs.player.offsetWidth;
      }
    },
    handleResize() {
      this.updatePlayerWidth();
      this.checkConditions();
    },
    checkConditions() {
      const screenWidth = window.innerWidth;
      if (
        (this.mvshow || this.showlist || this.lyricshow) &&
        screenWidth < 780
      ) {
        this.$store.commit('setSideBarShow', false);
        this.$eventBus.$emit("hide-side-bar");
      } else {
        this.$eventBus.$emit("show-side-bar");
        this.$store.commit('setSideBarShow', true);
      }
    },
    opmv() {
      if (this.mvid == 0) {
        Toast("暂无视频");
        return;
      }
      if (this.audio) {
        this.audio.pause();
      }
      this.lyricshow = false;
      this.showlist = false;
      if (this.oldmvurl && this.oldmvurl === this.mvurl && this.mvshow) {
        if (this.$refs.myvideo) {
          this.$refs.myvideo.pause();
        }
      } else if (this.oldmvurl && this.oldmvurl === this.mvurl && !this.mvshow) {
        if (this.$refs.myvideo) {
          this.$refs.myvideo.play();
        }
      }
      else {
        this.getmvurl();
      }
      this.mvshow = !this.mvshow;
    },

    getmvurl() {
      axios
        .get(this.api + "/mv/url", {
          params: { id: this.mvid, r: 1080 }
        })
        .then(response => {
          this.mvurl = response.data.data.url.replace(/^http:/, 'https:');
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleFullscreenChange() {
      if (!document.fullscreenElement) {
        // 当退出全屏时，设置字体大小为16
        this.fontSize = 16;
      }
    },
    toggleFullScreen() {
      const container = this.$refs.lyricsContainer;
      if (!document.fullscreenElement) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.mozRequestFullScreen) {
          // Firefox
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          // Chrome, Safari and Opera
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          // IE/Edge
          container.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
      }
    },
    scrollToCurrentLine() {
      if (!this.lyricshow) {
        return;
      }
      const container = this.$refs.lyricsContainer;
      if (container) {
        const activeLine = container.querySelector(".active-line");
        if (activeLine) {
          const containerHeight = container.clientHeight;
          const activeLineTop = activeLine.offsetTop;
          const activeLineHeight = activeLine.offsetHeight;
          const scrollTop =
            activeLineTop - containerHeight / 2 + activeLineHeight / 2;
          container.scrollTo({ top: scrollTop, behavior: "smooth" });
        }
      } else {
        console.error("lyricsContainer ref is not found");
      }
    },
    async copelrc() {
      if (this.lyrics.length < 5) {
        Toast.setDefaultOptions({ duration: 1000 });
        Toast("无歌词");
        this.lyricshow = false;
        return;
      }

      this.lyricshow = !this.lyricshow;
      this.showlist = false;
      this.mvshow = false;
      if (this.oldmvurl && this.oldmvurl === this.mvurl) {
        if (this.$refs.myvideo) {
          this.$refs.myvideo.pause();
        }

      }

    },
    async copylrc() {
      const textArea = document.createElement("textarea");
      textArea.value = this.lyc;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        Toast.setDefaultOptions({ duration: 1000 });
        Toast("歌词已复制到剪贴板");
      } catch (err) {
        Toast.setDefaultOptions({ duration: 1000 });
        Toast("歌词复制失败");
      }
      document.body.removeChild(textArea);
    },
    async copesong() {
      if (!this.audioUrl) {
        Toast.setDefaultOptions({ duration: 1000 });
        Toast("无法获取歌曲链接");
        return;
      }
      const textArea = document.createElement("textarea");
      textArea.value = this.audioUrl;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        Toast.setDefaultOptions({ duration: 1000 });
        Toast("歌曲链接已复制到剪贴板");
      } catch (err) {
        Toast.setDefaultOptions({ duration: 1000 });
        Toast("歌曲链接复制失败");
      }
      document.body.removeChild(textArea);
    },
    handleKeyDown(event) {
      if (this.sheetshow) {
        return;
      }
      if (event.keyCode === 32) {
        // 空格键
        this.togglePlay();
      } else if (event.keyCode === 37) {
        // 左侧方向键
        this.prevSong();
      } else if (event.keyCode === 39) {
        // 右侧方向键
        this.nextSong();
      } else if (event.key === "+") {
        // 右侧方向键
        this.fontSize += 2;
      } else if (event.key === "-") {
        this.fontSize = Math.max(10, this.fontSize - 2);
      }
    },
    handlePlaylist(playlistid) {
      this.list = [];
      this.audio.pause();
      this.changesong = false;
      this.isplay = false;
      this.playlistid = playlistid;
      this.getPlaylist(playlistid);
      setTimeout(() => {
        if (this.list.length > 1) {
          this.togglePlay();
        }
      }, 800);
    },
    handlesearch(keywords) {
      this.checkConditions();
      this.list = [];
      this.audio.pause();
      this.changesong = false;
      this.isplay = false;
      this.search(keywords);
      setTimeout(() => {
        if (this.list.length > 1) {
          this.togglePlay();
        }
      }, 800);
    },

    updateCurrentLine(currentTime) {
      // 根据当前播放时间找到对应的歌词行数
      for (let i = 0; i < this.lyrics.length; i++) {
        if (
          currentTime >= this.lyrics[i].time &&
          (!this.lyrics[i + 1] || currentTime < this.lyrics[i + 1].time)
        ) {
          // 如果当前时间在两行歌词的时间戳之间，则��当前行为第 i 行
          this.currentLine = i;
          break;
        }
      }
    },

    async getlist() {
      try {
        const response = await this.getsonglist();
        const songlist = response.data;
        // console.log(songlist);
        this.list = songlist.map(song => ({
          name: song.songname,
          id: song.songid,
          songer: song.arname
        }));
        const songid = this.list[0].id;
        this.songid = songid;
        this.getsheet(songid);
        this.getPic(songid);
        this.getmvid(songid);
      } catch (error) {
        console.error(error);
      }
    },
    async getPlaylist(id) {
      try {
        const response = await axios.get(this.api + "/playlist/track/all", {
          params: { id: id }
        });
        const songlist = response.data.songs;
        this.list = songlist.map(song => ({
          name: song.name,
          id: song.id,
          songer: song.ar[0].name
        }));
        const songid = this.list[0].id;
        this.songid = songid;
        this.getsheet(songid);
        this.getPic(songid);
        this.getmvid(songid);
      } catch (error) {
        console.error(error);
      }
    },

    async search(keywords) {
      try {
        const response = await axios.get(this.api + "/search", {
          params: {
            keywords: keywords,
            type: 1,
            offset: 0,
            limit: 100
          }
        });
        const songlist = response.data.result.songs;

        this.list = songlist.map(song => ({
          name: song.name,
          id: song.id,
          songer: song.artists[0].name
        }));
        this.playshow = true;
        this.isback = false;
        const songid = this.list[0].id;
        this.songid = songid;
        this.getPic(songid);
        this.getmvid(songid);
      } catch (error) {
        this.eer = error;
      }
      setTimeout(() => {
        const playerWidth = this.$refs.player.offsetWidth;
        this.playerWidth = playerWidth;
        this.showlist = true;
      }, 300);
      this.eer = null;
    },
    playlist(id) {
      this.getAudio(id);
      this.getLyrics(id);
    },
    async togglePlay() {
      if (this.mvshow) {
        this.mvshow = false;
      }
      
      if (this.oldmvurl && this.oldmvurl === this.mvurl && this.$refs.myvideo) {
        this.$refs.myvideo.pause();
      }

      try {
        if (this.audio.paused) {
          if (this.list[0].id === this.songid) {
            await this.playlist(this.list[0].id);
          } else {
            await this.audio.play();
            this.isplay = true;
          }
        } else {
          this.audio.pause();
          this.$eventBus.$emit("noaudioplay", "noaudioplay");
          this.changesong = true;
          this.isplay = false;
        }
      } catch (error) {
        console.error('播放控制出错:', error);
      }
    },
    formatTime(time) {
      if (!time || time <= 0) {
        return "00:00";
      }
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);

      const paddedMinutes = String(minutes).padStart(2, "0");
      const paddedSeconds = String(seconds).padStart(2, "0");

      return `${paddedMinutes}:${paddedSeconds}`;
    },
    seek() {
      this.changesong = false;
      if (this.audio) {
        this.audio.currentTime = this.currentTime;
      }
    },
    parseLyrics(text) {
      const timeRegex = /\[(\d{2}):(\d{2})\.(\d{2,3})\](.*)/;
      
      return text.split("\n")
        .reduce((acc, line) => {
          const matches = line.match(timeRegex);
          if (matches) {
            const [, minutes, seconds, milliseconds, lyric] = matches;
            const time = parseInt(minutes) * 60 + 
                        parseInt(seconds) + 
                        parseInt(milliseconds) / 1000;
            
            if (lyric.trim()) {
              acc.push({ time, lyric: lyric.trim() });
            }
          }
          return acc;
        }, []);
    },

    nextSong() {
      if (this.audio) {
        this.audio.pause();
      }
      this.mvurl = "";

      if (this.mvshow) {
        this.mvshow = false;
      }
      this.changesong = true;
      const currentIndex = this.list.findIndex(item => item.id === this.songid);

      let nextIndex;

      if (
        currentIndex !== -1 &&
        currentIndex < this.list.length - 1 &&
        this.mode !== "random"
      ) {
        // 当前不是最后一首歌曲，且不是随机模式，播放下一首
        nextIndex = currentIndex + 1;
      } else {
        // 根据播放模式选择下一首歌曲
        if (this.mode === "listLoop") {
          // 列表循环模式，播放第一首歌曲
          nextIndex = (currentIndex + 1) % this.list.length; // 使用取余确保循环播放
        } else if (this.mode === "random") {
          // 随机模式，随机选择一首歌曲
          nextIndex = Math.floor(Math.random() * this.list.length);
        } else if (this.mode === "singleLoop") {
          // 单曲循环模式，继续播放当前歌曲
          nextIndex = currentIndex;
        }
      }

      if (this.mode === "singleLoop") {
        // 单曲循环模式，设置下一首歌曲ID为当前歌曲ID
        nextIndex = currentIndex;
      }

      if (nextIndex !== undefined) {
        const nextSong = this.list[nextIndex];
        this.playSong(nextSong);
        this.songid = nextSong.id; // 设置当前歌曲ID

        if (this.showlist) {
          // 在下一次DOM更新后计算滚动位置
          this.$nextTick(() => {
            const container = document.querySelector(".song-list");
            const songItems = document.querySelectorAll(".song-item");
            const currentSongItem = songItems[nextIndex];

            // 获取容器的高度
            const containerHeight = container.clientHeight;

            // 获取当前歌曲元素的高度和相对容器顶部的偏移量
            const currentSongItemHeight = currentSongItem.offsetHeight;
            const currentSongItemOffsetTop =
              currentSongItem.offsetTop - container.offsetTop;

            // 计算当前歌曲应滚动到的位置
            const scrollPosition =
              currentSongItemOffsetTop -
              containerHeight / 2 +
              currentSongItemHeight / 2;

            // 设置滚动位置
            container.scrollTop = scrollPosition;
          });
        }
      }
    },

    prevSong() {
      if (this.audio) {
        this.audio.pause();
      }
      this.mvurl = "";

      if (this.mvshow) {
        this.mvshow = false;
      }
      this.changesong = true;
      const currentIndex = this.list.findIndex(item => item.id === this.songid);
      let prevIndex;

      if (currentIndex !== -1 && currentIndex > 0) {
        prevIndex = currentIndex - 1;
      } else {
        console.log("已经是第一首歌曲了。");
      }

      if (prevIndex !== undefined) {
        const prevSong = this.list[prevIndex];
        this.playSong(prevSong);

        this.$nextTick(() => {
          const container = document.querySelector(".song-list");
          const songItems = document.querySelectorAll(".song-item");
          const currentSongItem = songItems[prevIndex];
          if (this.showlist) {
            const containerHeight = container.clientHeight;
            const currentSongItemHeight = currentSongItem.offsetHeight;
            const currentSongItemOffsetTop =
              currentSongItem.offsetTop - container.offsetTop;
            const scrollPosition =
              currentSongItemOffsetTop -
              containerHeight / 2 +
              currentSongItemHeight / 2;
            container.scrollTop = scrollPosition;
          }
        });
      }
    },

    playSong(song) {
      this.changesong = false;
      this.songid = song.id;
      this.getAudio(this.songid);
      this.getsheet(this.songid);
      this.getPic(this.songid);
      this.getLyrics(this.songid);
      this.getmvid(this.songid);
    },
    getAudio(id) {
      axios
        .get(this.api + "/song/url", {
          params: { id: id }
        })
        .then(response => {
          this.audioUrl = response.data.data[0].url.replace(/^http:/, 'https:');
          this.audio.src = this.audioUrl;

          this.audio.onpause = () => {
            this.lastPlayedTime = this.currentTime;
          };
          this.$eventBus.$emit("audioplay", "audioplay");

          this.audio.onplay = () => {
            // 如果是从暂停状态恢复播放，则设置当前时间为上次暂停的时间
            if (!this.changesong) {
              this.currentTime = 0;
            } else {
              this.audio.currentTime = this.lastPlayedTime;
            }
          };
          this.audio.onended = () => {
            this.nextSong();
          };
          this.audio.play();
          this.isplay = true;
          this.audio.onprogress = e => {
            this.duration = e.target.duration;
          };
          this.audio.ontimeupdate = () => {
            this.currentTime = this.audio.currentTime;
          };
        })
        .catch(error => {
          console.log(error);
        });
    },
    getPic(id) {
      axios
        .get(this.api + "/song/detail", {
          params: { ids: id }
        })
        .then(response => {
          this.pic = response.data.songs[0].al.picUrl.replace(/^http:/, 'https:');
          this.songname = response.data.songs[0].name;
          this.$eventBus.$emit("songname", this.songname);
          this.singer = response.data.songs[0].ar[0].name;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getsheet(id) {
      axios
        .get(this.api + "/sheet/list", {
          params: { id: id }
        })
        .then(response => {
          const result = response.data.data.musicSheetSimpleInfoVOS;
          if (result && result.length >= 1) {
            this.songsheet = result;
            this.issheet = true;
          } else {
            this.songsheet = [];
            this.issheet = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    opsheet() {
      if (this.songsheet && this.songsheet.length >= 1) {
        this.sheetshow = true;
        this.$eventBus.$emit("hide-side-bar");
        if (this.oldmvurl && this.oldmvurl === this.mvurl) {
          if (this.$refs.myvideo) {
            this.$refs.myvideo.pause();
          }

        }
      }
    },
    getLyrics(id) {
      axios
        .get(this.api + "/lyric", {
          params: { id: id }
        })
        .then(response => {
          this.lyrics = this.parseLyrics(response.data.lrc.lyric);

          // 处理歌词文本
          this.lyricstext = this.lyrics
            .map(item => {
              if (item.lyric) {
                return item.lyric;
              } else {
                return null;
              }
            })
            .filter(item => item !== null)
            .join(",\n");
          this.lyc = this.lyricstext;

          //console.log('歌词文本:', this.lyricstext); // Optional: For debugging
        })
        .catch(error => {
          console.log(error);
        });
    },
    getmvid(id) {
      axios
        .get(this.api + "/song/detail", {
          params: { ids: id }
        })
        .then(response => {
          const mvid = response.data.songs[0].mv;
          this.mvid = mvid;
          //console.log(mvid);
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeMode() {
      const PLAY_MODES = {
        listLoop: { next: 'random', icon: 'icon-xunhuan' },
        random: { next: 'singleLoop', icon: 'icon-suijibofang' },
        singleLoop: { next: 'listLoop', icon: 'icon-danquxunhuan' }
      };

      this.mode = PLAY_MODES[this.mode].next;
    },
    getModeIconClass() {
      const PLAY_MODES = {
        listLoop: { next: 'random', icon: 'icon-xunhuan' },
        random: { next: 'singleLoop', icon: 'icon-suijibofang' },
        singleLoop: { next: 'listLoop', icon: 'icon-danquxunhuan' }
      };

      return PLAY_MODES[this.mode].icon;
    },
    togglelist() {
      // 切换 showlist 的状态
      this.showlist = !this.showlist;
      if (this.lyricshow) {
        this.lyricshow = false;
      }
      if (this.mvshow) {
        this.mvshow = false;
      }
      if (this.oldmvurl && this.oldmvurl === this.mvurl) {
        if (this.$refs.myvideo) {
          this.$refs.myvideo.pause();
        }

      }
    },
    sheetclose() {
      this.$eventBus.$emit("show-side-bar");
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;
    },
    toggleback() {
      this.isback = !this.isback;
      if (this.mvshow) {
        this.mvshow = false;
      }
      if (this.oldmvurl && this.oldmvurl === this.mvurl) {
        if (this.$refs.myvideo) {
          this.$refs.myvideo.pause();
        }

      }
      if (this.showlist) {
        this.showlist = false;
        this.$eventBus.$emit("show-side-bar");
      }
      if (this.lyricshow) {
        this.lyricshow = false;
        this.$eventBus.$emit("show-side-bar");
      }

      this.playshow = !this.isback;
      this.playshow = !this.isback;
      setTimeout(() => {
        const playerWidth = this.$refs.player.offsetWidth;
        this.playerWidth = playerWidth;
      }, 0);
    }
  }
};
</script>

<style scoped>
.player {
  max-width: 420px;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 0;
  margin: 0;
  color: #fbfbfb;
  background-color: #2c3e50;
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.song-list {
  position: absolute;
  bottom: 100%;
  left: 0;
  box-sizing: border-box;
  max-height: 47vh;
  overflow-y: scroll;
  z-index: 10;
  background-color: #fcfafa;
  border: 1px solid #cbc9c9;
}

.lyrics-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  box-sizing: border-box;
  max-height: 47vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  z-index: 10;
  text-align: center;
  color: #ccc8c8;
  background-color: #2c3e50;
  border-bottom: 0.1px solid #ccc8c8;
  transition: all 0.25s ease;
}

.mv-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  box-sizing: border-box;
  z-index: 10;
  object-fit: contain;
  transition: all 0.25s ease;
}

.lyric-area {
  height: 20px;
  background-color: #2c3e50;
  position: sticky;
  top: 0;
  z-index: 15;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.fll-screen {
  margin-top: 1px;
  margin-right: 25px;
  color: #f0e9e9;
}

.lyric-line {
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0.5;
  padding-top: 10px;
}

.lyrics-container .active-line {
  opacity: 1;
  color: rgba(0, 255, 221, 0.68);
}

@media (max-width: 768px) {
  .panelshow {
    min-width: 100vw;
  }

  .song-list {
    max-height: 49vh;
  }

  .lyrics-container {
    max-height: 49vh;
  }

  .panel {
    height: 7vh !important;
  }

  .avatar-area {
    height: 7vh !important;
    width: 7vh !important;
  }

  .back {
    height: 7vh !important;
  }

  .current-time {
    margin-bottom: -4px !important;
  }
}

/* iPhone 和小屏设备 */
@media (max-width: 768px) {
  .panelshow {
    min-width: 100vw;
  }

  .song-list {
    max-height: 49vh;
  }

  .lyrics-container {
    max-height: 49vh;
  }

  .panel {
    height: 7vh !important;
  }

  .avatar-area {
    height: 7vh !important;
    width: 7vh !important;
  }

  .back {
    height: 7vh !important;
  }

  .current-time {
    margin-bottom: -4px !important;
  }
}

/* iPad Mini & iPad 9.7" */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)
  and (orientation: portrait) {
  .song-list {
    max-height: 52vh;
  }

  .lyrics-container {
    max-height: 52vh;
  }

  .panel {
    height: 6vh !important;
  }

  .avatar-area {
    height: 6vh !important;
    width: 6vh !important;
  }

  .back {
    height: 6vh !important;
  }
}

/* iPad 10.2" & iPad Air */
@media only screen 
  and (min-device-width: 810px) 
  and (max-device-width: 1080px)
  and (orientation: portrait) {
  .song-list {
    max-height: 54vh;
  }

  .lyrics-container {
    max-height: 54vh;
  }

  .panel {
    height: 5.5vh !important;
  }

  .avatar-area {
    height: 5.5vh !important;
    width: 5.5vh !important;
  }

  .back {
    height: 5.5vh !important;
  }
}

/* iPad Pro 11" & 12.9" */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (orientation: portrait) {
  .song-list {
    max-height: 56vh;
  }

  .lyrics-container {
    max-height: 56vh;
  }

  .panel {
    height: 5vh !important;
  }

  .avatar-area {
    height: 5vh !important;
    width: 5vh !important;
  }

  .back {
    height: 5vh !important;
  }
}

.song-list::-webkit-scrollbar {
  width: 0;
}

.avatar-area {
  position: relative;
  height: 9vh;
  width: 9vh;
}

.player-avatar {
  width: 100%;
  height: 100%;
}

.pic-btn {
  position: absolute;
  top: 35%;
  left: 35%;
  transform: scale(1.6);

  opacity: 0.8;
}

.pic-btn-play {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: scale(0.9);
  opacity: 1;
  overflow: hidden;

  color: brown;
}

.panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 9vh;
  justify-content: space-between;
}

.current-song {
  color: red;
}

.player-controls {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.player-info {
  padding-left: 3px;
  display: flex;
  flex-direction: column;
  width: 150px;
  border: 0px solid #f4f4f4;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.back {
  position: relative;
  width: 15px;
  height: 9vh;
  background: #2c3e50;
  border-left: 0px solid #f4f4f4;
}

.back-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(3) scaleX(1.2);
}

.player-btn {
  position: relative;
  flex: 1;
  top: 15%;
  height: 80%;
  align-items: center;
  justify-content: space-around;
  display: flex;
}

.player-song-name {
  width: 100%;
  font-size: 12px;
  margin-left: 2px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player-singer-name {
  width: 100%;
  font-size: 10px;
  margin-left: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.control-btn {
  transform: scale(1.5);
  margin-right: 20px;
  color: #f9f8f8;
  cursor: pointer;
}

.control-btnci {
  transform: scale(1.5);
  margin-right: 10px;
  margin-left: -10px;
  margin-bottom: 5px;
  color: #f9f8f8;
  cursor: pointer;
}

.footer {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: space-around;
}

.control-btn-volume {
  margin-right: 3px;
  transform: scaleX(0.9), scaleY(0.8);
  color: #f9f8f8;
  cursor: pointer;
}

.control-btn-mode {
  margin-right: 3px;
  transform: scale(0.9);
  color: #f9f8f8;
  cursor: pointer;
}

.control-btn-list {
  margin-right: 3px;
  transform: scale(1);
  color: #f9f8f8;
  cursor: pointer;
}

.control-btn-qupu {
  margin-right: 3px;
  transform: scaleY(0.7);
  color: #f9f8f8;
  cursor: pointer;
}

.control-btn-mv {
  margin-right: 3px;
  transform: scale(0.9);
  color: #f9f8f8;
  cursor: pointer;
}

input[type="range"] {
  width: 40%;
  appearance: none;
  height: 6px;
  border-radius: 4px;
  background: #ffffff;
  margin-bottom: -2px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4361e7;
  cursor: pointer;
}

.current-time {
  margin-right: 7px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: -3px;
}

.song-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #cbc9c9;
}

.song-index {
  margin-right: 5px;
}

.song-details {
  display: flex;
  flex: 1;

  justify-content: space-between;
  align-items: center;
}

.song-name {
  margin-right: 5px;
  font-size: 14px;
  position: relative;
  width: 160px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.song-artist {
  font-size: 12px;
  position: relative;
  width: 100px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.green {
  color: rgb(64, 255, 0);
}

.red {
  color: rgb(255, 0, 0);
}

.nogreen {
  color: #f9f8f8;
}
</style>
