<template>
    <div class="content-site" @touchstart="sideshow">
        <url-team ref="commonWebsites" title="常用网站" :weblist="weblist" inco="直达" @click="handleChildClick"></url-team>
        <url-team ref="NetWebsites" title="Net station" :weblist="netlist" inco="内网" @click="handleChildClick"></url-team>
        <url-team ref="majorWebsites" title="专业网站" :weblist="majorlist" inco="直达" @click="handleChildClick"></url-team>
        <url-team ref="developWebsites" title="开发工具" :weblist="developlist" inco="直达" @click="handleChildClick"></url-team>
        <url-team ref="toolWebsites" title="软件工具" :weblist="toollist" inco="直达" @click="handleChildClick"></url-team>
        <url-team ref="infoWebsites" title="信息资讯" :weblist="infolist" inco="直达" @click="handleChildClick"></url-team>
    
        <WebinFo></WebinFo>
        <el-dialog :visible.sync="popushow" width="90%" :title="title" center >
            <div style="height: 350px; overflow: auto;">
                <el-table size="small" :data="urllist" highlight-current-row border>
                    <el-table-column align="center" label="添加" width="70">
                        <template v-slot:header>
                            <el-button size="mini" style="width: 100%;" type="primary" @click="addurl">添加</el-button>
                        </template>
                        <template slot-scope="scope">
                            {{ scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="网址描述" width="200">
                        <template slot-scope="scope">
                            <editable-cell v-model="urllist[scope.$index].mainname" style="width: 100%;"></editable-cell>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="网址名称" width="100">
                        <template slot-scope="scope">
                            <editable-cell v-model="urllist[scope.$index].name" style="width: 100%;"></editable-cell>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mainurl" align="center" label="主要地址">
                        <template slot-scope="scope">
                            <editable-cell v-model="urllist[scope.$index].mainurl"></editable-cell>
                        </template>
                    </el-table-column>
                    <el-table-column prop="url" align="center" label="次要地址">
                        <template slot-scope="scope">
                            <editable-cell v-model="urllist[scope.$index].url"></editable-cell>
                        </template>
                    </el-table-column>


                    <el-table-column align="center" label="网址图标" width="120">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: space-between; ">

                                <input style="display: none;" :id="getFileInputRef(scope.row.id)" type="file"
                                    :ref="getFileInputRef(scope.row.id)" @change="handleFileInputChange(scope.row.id)">
                                <el-button size="mini" @click="openFileInput(scope.row.id)">上传</el-button>
                                <img v-if="scope.row.imgname" :src="'/assets/ico/' + scope.row.imgname"
                                    style="max-width: 30px; max-height: 30px;">
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="操作" min-width="100">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="updatelink(scope.row)">更新</el-button>
                            <el-button size="mini" type="danger" @click="deletelink(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="addshow" width="400px" :title="title" center>
            <div style="height: 350px; overflow: auto;">
                <el-form ref="form" label-width="100px">
                    <el-form-item label="网址描述">
                        <el-input v-model="addlist.mainname"></el-input>
                    </el-form-item>
                    <el-form-item label="网址名称">
                        <el-input v-model="addlist.name"></el-input>
                    </el-form-item>
                    <el-form-item label="主要地址">
                        <el-input v-model="addlist.mainurl"></el-input>
                    </el-form-item>
                    <el-form-item label="次要地址">
                        <el-input v-model="addlist.url"></el-input>
                    </el-form-item>
                    <el-form-item label="网址图标">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <el-input v-model="addlist.imgname" style="width: 40%;"></el-input>
                            <input type="file" style="display: none;" ref="fileInput" @change="uploadFile" />
                            <el-button type="success" class="loadfile" @click="chooseFile">选择图片</el-button>
                            <img :src="imgurl" style="max-width: 40px; max-height: 40px; ">
                        </div>
                    </el-form-item>
                </el-form>
                <div style="text-align: center;">
                    <el-button style="width: 50%;" type="primary" @click="addlink">添加</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog 
            :visible.sync="showLogin"
            width="340px"
            center
            :show-close="true"
            custom-class="login-dialog"
            title="登录">
            <el-form 
                ref="loginForm"
                :model="loginForm"
                :rules="loginRules"
                class="login-form">
                
                <el-form-item prop="username" class="form-item">
                    <el-input
                        v-model="loginForm.username"
                        prefix-icon="el-icon-user"
                        placeholder="账号"
                        clearable
                        class="custom-input">
                    </el-input>
                </el-form-item>

                <el-form-item prop="password" class="form-item">
                    <el-input
                        v-model="loginForm.password"
                        prefix-icon="el-icon-lock"
                        type="password"
                        placeholder="密码"
                        show-password
                        class="custom-input">
                    </el-input>
                </el-form-item>

                <el-button 
                    type="primary" 
                    :loading="loading"
                    class="login-btn"
                    @click="submitLogin('loginForm')">
                    登 录
                </el-button>
                
                <div class="cancel-link" @click="cancelLogin">取消</div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import UrlTeam from './UrlTeam.vue';
import WebinFo from './WebinFo.vue';
import editableCell from './EditableCell.vue';
import { Toast } from 'vant';

export default {
    name: "ContentPage",
    components: {
        UrlTeam,
        WebinFo,     
        editableCell

    },

    data() {
        return {
            netlist: [],
            weblist: [],
            developlist: [],
            majorlist: [],
            toollist: [],
            infolist: [],
            urllist: [],
            loading: false,
            loginForm: {
                username: '',
                password: ''
            },
            loginRules: {
                username: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ]
            },
            addlist: {
                mainname: "",
                name: "",
                mainurl: "",
                url: "",
                imgname: ""
            },
            popushow: false,
            addshow: false,
            isLogin: false,
            showLogin: false,
            imgfile: '',
            imgurl: '',
            title: ''
        }
    },
    created() {
        this.$eventBus.$on('scroll-to-component', this.scrollToComponent);
        this.getnet();
        this.getweb();
        this.getdevelop();
        this.getmajor();
        this.gettool();
        this.getinfo();
    },
    beforeDestroy() {
        this.$eventBus.$off('scroll-to-component', this.scrollToComponent);
    },
    watch: {
        popushow(newVal) {
            if (!newVal) {
                this.urllist = [];
                this.title = '';
            }
        },
        addshow(newVal) {
            if (!newVal) {
                this.addlist = [];
                this.imgfile = '';
                this.imgurl = '';
            }
        }
    },
    methods: {
        cancelLogin() {
            this.$refs.loginForm.resetFields();
            this.showLogin = false;
        },

        submitLogin(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    return false;
                }

                try {
                    this.loading = true;
                    const result = await this.getlogin(
                        this.loginForm.username,
                        this.loginForm.password
                    );
                    
                    if (result.data.message === "登录成功") {
                        this.$message.success('登录成功');
                        this.showLogin = false;
                        this.isLogin = true;
                        this.$refs[formName].resetFields();
                    } else {
                        this.$message.error(result.data.message);
                    }
                } catch (error) {
                    this.$message.error('登录失败，请稍后重试');
                    console.error('登录错误:', error);
                } finally {
                    this.loading = false;
                }
            });
        },

        chooseFile() {
            this.$refs.fileInput.click();
        },
        uploadFile(event) {
            this.imgfile = event.target.files[0];
            this.imgurl = URL.createObjectURL(this.imgfile);
            this.addlist.imgname = event.target.files[0].name;
        },
        async addlink() {
            if (
                this.addlist.mainname === "" &&
                this.addlist.name === "" &&
                this.addlist.mainurl === "" &&
                this.addlist.url === "" &&
                this.addlist.imgname === ""
            ) {
                Toast.setDefaultOptions({ duration: 500 });
                Toast('请填写后再提交');
                return;
            }
            const file = this.imgfile;
            const title = this.title;
            const data = JSON.stringify(this.addlist);
            const result = await this.adlist(file, data, title);
            const res = result.data.message;
            if (res == "插入成功") {
                Toast.setDefaultOptions({ duration: 500 });
                Toast(res);
                this.popushow = false;
                this.addshow = false;
                this.getweb();
                this.refresh(title);
                this.scrollto(title);
            }

        },

        refresh(title) {
            const titleMap = {
                '常用网站': 'getweb',
                '信息资讯': 'getinfo',
                'Net station': 'getnet',
                '专业网站': 'getmajor',
                '开发工具': 'getdevelop',
                '软件工具': 'gettool'
            };
            const functionName = titleMap[title];
            if (functionName && typeof this[functionName] === 'function') {
                this[functionName]();
            } else {
                console.error('未找到对应的函数');
            }
        },

        scrollto(title) {
            const titleMap = {
                '常用网站': 'commonWebsites',
                '信息资讯': 'infoWebsites',
                'Net station': 'NetWebsites',
                '专业网站': 'majorWebsites',
                '开发工具': 'getdevelop',
                '软件工具': 'toolWebsites'
            };

            const scrollName = titleMap[title];
            if (scrollName) {
                this.scrollToComponent(scrollName)
            } else {
                console.error('未找到对应的滚动组件');
            }
        },
        getFileInputRef(id) {
            return `imageInput_${id}`;
        },
        openFileInput(id) {
            this.$refs[this.getFileInputRef(id)].click();
        },
        handleFileInputChange(id) {
            const file = this.$refs[this.getFileInputRef(id)].files[0];

            const row = this.urllist.find(item => item.id === id);
            this.uploadImage(row, file);
        },
        async uploadImage(row, file) {
            var title = this.title;
            var data = JSON.stringify(row);
            const result = await this.upimg(file, data, title);
            const res = result.data.message;
            if (res == "更新成功") {
                Toast.setDefaultOptions({ duration: 500 });
                Toast(res);
                this.popushow = false;
                this.getweb();
                this.refresh(title);
                this.scrollto(title);
            }

        },

        async getnet() { this.netlist = (await this.getnetlist()).data; },
        async getweb() { this.weblist = (await this.getweblist()).data; },
        async getdevelop() { this.developlist = (await this.getdeveloplist()).data; },
        async getmajor() { this.majorlist = (await this.getmajorlist()).data; },
        async gettool() { this.toollist = (await this.gettoollist()).data; },
        async getinfo() { this.infolist = (await this.getinfolist()).data; },


        handleChildClick(title, weblist) {
            if (this.isLogin) {
                this.title = title;
                this.urllist = weblist;
                this.popushow = true;
            } else {
                this.showLogin = true;
            }
        },
        addurl() {
            this.addshow = true;
        },
        async updatelink(row) {
            const title = this.title;
            const data = JSON.stringify(row);

            const result = await this.uplist(data, title);
            const res = result.data.message;
            if (res == "更新成功") {
                Toast.setDefaultOptions({ duration: 500 });
                Toast(res);
                this.popushow = false;
                this.getweb();
                this.refresh(title);
                this.scrollto(title);
            }
        },

        async deletelink(row) {
            const id = row.id;
            const title = this.title;
            const result = await this.dltlist(id, title);
            const res = result.data.message;
            if (res == "删除成功") {
                Toast.setDefaultOptions({ duration: 500 });
                Toast(res);
                this.popushow = false;
                this.getweb();
                this.refresh(title);
                this.scrollto(title);
            }
        },

        sideshow() {
            this.$eventBus.$emit("show-side-bar");
        },
        scrollToComponent(componentRef) {
            const targetComponent = this.$refs[componentRef];
            if (targetComponent) {
                targetComponent.$el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
};
</script>

<style  scoped>
.content-site {
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
    border: 0px solid #d42828;
}

.login-dialog {
    border-radius: 12px;
    overflow: hidden;
}



.login-form {
   
    background: #fff;
}

.login-header {
    text-align: center;
    margin-bottom: 5px;
}

.login-header h2 {
    font-size: 30px;
    color: #1a1a1a;
    margin: 0;
    font-weight: 800;
}

.form-item.el-form-item {
    margin-bottom: 20px;
}

.custom-input >>> .el-input__inner {
    height: 35px;
    border: none;
    background: #f5f5f5;
    border-radius: 8px;
    padding-left: 36px;
    font-size: 14px;
    color: #1a1a1a;
}

.custom-input >>> .el-input__inner:focus {
    background: #eef5ff;
    box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
}

.custom-input >>> .el-input__prefix {
    left: 12px;
}

.custom-input >>> .el-input__icon {
    line-height:35px;
    color: #909399;
    font-size: 16px;
}

.login-btn {
    width: 100%;
    height: 35px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    background: #409EFF;
    border: none;
    margin: 4px 0 12px;
}

.login-btn:hover {
    background: #66b1ff;
}

.cancel-link {
    text-align: center;
    color: #909399;
    font-size: 13px;
    cursor: pointer;
    padding: 4px;
}

.cancel-link:hover {
    color: #409EFF;
}

.login-form >>> .el-form-item__error {
    padding-top: 2px;
    font-size: 12px;
}

.custom-input >>> .el-input__inner::placeholder {
    color: #909399;
}


.custom-input >>> .el-input__inner:hover {
    border-color: transparent;
}
</style>